import { gql } from 'graphql-request'
import { GenericBlock } from './generic'

export const videoFragment = gql`
  fragment videoFragment on Video {
    id
    video
    closedCaptions
    player
    videoData
  }
`

export interface Video extends GenericBlock<'Video'> {
  video: string
  player: string
  closedCaptions?: string
  videoData?: any
}
