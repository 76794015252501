import { gql } from 'graphql-request'

import { GenericBlock } from '../generic'

export const quizFragment = gql`
  fragment quizFragment on Quiz {
    id
    season {
      id
      enableTeams
    }
    memberTeam
    current {
      id
      title
      introTitle
      introSubtitle
      hasAnswered
      questions {
        __typename
        ... on SingleChoice {
          id
          question
          instructions
          answers {
            id
            answer
            yourVote
          }
          timeLimit
        }
        ... on MultipleChoice {
          id
          question
          instructions
          answers {
            id
            answer
            yourVote
          }
          timeLimit
        }
        ... on Ranking {
          id
          question
          instructions
          answers {
            id
            answer
            yourPosition
          }
          timeLimit
        }
      }
      closingDate
    }

    previous {
      id
      name
      title
      introTitle
      introSubtitle
      hasAnswered
      questions {
        __typename
        ... on SingleChoice {
          id
          question
          instructions
          answers {
            id
            answer
            correct
            percent
            yourVote
          }
        }
        ... on MultipleChoice {
          id
          question
          instructions
          answers {
            id
            answer
            correct
            percent
            yourVote
          }
        }
        ... on Ranking {
          id
          question
          instructions
          answers {
            id
            answer
            correctPosition
            averagePosition
            yourPosition
          }
        }
      }
      closingDate
    }

    next {
      id
      name
      openingDate
    }
  }
`

export interface Quiz extends GenericBlock<'Quiz'> {
  id: string
  season: {
    id: string
  }
  current: {
    id: string
    name: string
    title: string
    introTitle: string
    introSubtitle: string
    hasAnswered: boolean
    questions: {
      id: string
      question: string
      instructions: string
      answers: {
        id: string
        answer: string
        timeLimit: number
        yourVote?: boolean
        yourPosition?: number
      }[]
    }[]
    closingDate: string
  }
  previous: {
    id: string
    name: string
    title: string
    introTitle: string
    introSubtitle: string
    hasAnswered: boolean
    questions: {
      id: string
      question: string
      instructions: string
      answers: {
        id: string
        answer: string
        correct?: boolean
        percent?: number
        correctPosition?: number
        averagePosition?: number
        yourVote?: boolean
        yourPosition?: number
      }[]
    }[]
    closingDate: string
  }
  next: {
    id: string
    title: string
    openingDate: string
  }
}
